@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body::-webkit-scrollbar {
  width: 10px;
  height: 8px;

}

body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

body::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

body::-webkit-scrollbar-track:hover,
body::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.text-error {
  color: #d32f2f;
}
